<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <span class="text-h3">{{ $t("summary") }}</span>
      <download-file-button
        :loading="isDownloading"
        @click="downloadFile"
      />
    </div>
    <div class="dashboard-slider-tags">
      <div
        class="scroll-btn"
        @click="scrollLeft"
      >
        <v-icon> mdi-chevron-left </v-icon>
      </div>
      <div class="content-wrapper">
        <v-card
          v-for="(row, i) in items"
          :key="i"
          :loading="isLoading"
          class="Tag_card"
        >
          <!-- <div class="text-hidden">
            <label>{{ $t("name") }}:</label> &nbsp;
            <span>{{ row.vehicle_variant_name }}</span>
          </div> -->
          <div
            v-for="(val, key) in row"
            :key="key"
            class="text-hidden"
          >
            <label>{{ $t(key) }}:</label>
            <span>{{ val }}</span>
          </div>
        </v-card>
      </div>
      <div
        class="scroll-btn"
        @click="scrollRight"
      >
        <v-icon> mdi-chevron-right </v-icon>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  components: {
    downloadFileButton: () =>
      import("@/components/base/DownloadFileButtons.vue"),
  },
  props: {
    isLoading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    ...mapGetters({
      isDownloading: "journeyReports/getIsSummaryExport",
    }),
  },
  methods: {
    downloadFile(val) {
      this.$emit("exportSummary", val);
    },
    scrollLeft() {
      document
        .getElementsByClassName("content-wrapper")[0]
        .scrollTo({ left: 0, behavior: "smooth" });
    },
    scrollRight() {
      const t = document.getElementsByClassName("content-wrapper")[0];
      // const w = t.offsetWidth;
      const sl = t.scrollLeft + 250;
      t.scrollTo({ left: sl, behavior: "smooth" });
    },
  },
};
</script>
<style lang="sass" scoped>
.dashboard-slider-tags
  // margin-top: 2rem
  user-select: none !important
  display: flex
  justify-content: space-between
  .scroll-btn
    cursor: pointer
    flex-basis: 30px
    display: flex
    justify-content: center
    align-items: center
    &:hover
      background: #e5e2e2 !important

  .content-wrapper
    // height: 100px
    flex: 1
    padding: 1rem 1.5rem
    display: flex
    align-items: center
    gap: 1rem
    overflow-x:  auto

    // .Tag_card div:first-child
    //   padding: 0.5rem 1rem

    .Tag_card
      height: fit-content
      width:320px
      height: auto
      margin: 0
      cursor: pointer
      transition: 0.7s all ease
      transition: 2s width linear
      .text-hidden
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
      div
        padding: 0rem 1rem
      &:hover
        width: fit-content
        color: #20a390
        box-shadow: 2px 1px 8px #20a390 !important
        // transform : scale3d(1.2, 1.3, 1.9)
        // z-index: 9
        // height: 100px
      label
        font-size: 12px
        display: inline-block
        width: 12rem
      hr
        background: green
      span
        font-size: 14px
        font-weight: 600

// .content-wrapper
//   *::-webkit-scrollbar
//     width: 30px
// //  -ms-overflow-style: none !important
// //     scrollbar-width: none !important
// //     ::-webkit-scrollbar
// //       display: none !important
</style>
<style scoped>
.content-wrapper::-webkit-scrollbar {
  display: none !important;
  /* width: 12px; */
}

/* .content-wrapper::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
    }
    
    .content-wrapper::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    } */
</style>
